<template>
  <div>
    <headerNav activeIndex="2" productsIndex="3"></headerNav>
    <div class="productsBody">
      <div class="productsImg">
        <div>
          <router-link to="/contact"><el-button type="primary" class="formBtn" >免费咨询</el-button></router-link>
        </div>
      </div>
      <div class="titleImg">
        <img src="../../assets/img/ldyTitle1.png" />
      </div>
      <div class="advantages">
        <div class="advantagesDiv">
          <img src="../../assets/img/lianjiebao1.png" />
          <div class="advantagesTitle">多渠道发票采集</div>
          <div class="advantagesContent">微信、支付宝、智能文件识别等 多收票渠道接入，实现自动收票</div>
        </div>
        <div class="advantagesDiv">
          <img src="../../assets/img/lianjiebao2.png" />
          <div class="advantagesTitle">精准识别与验票</div>
          <div class="advantagesContent">黑科技精准文字识别，直连国家 税务平台验 真查重，让假票无处遁形</div>
        </div>
        <div class="advantagesDiv">
          <img src="../../assets/img/lianjiebao3.png" />
          <div class="advantagesTitle">差旅全流程管理</div>
          <div class="advantagesContent">差旅全流程管理</div>
        </div>
        <div class="advantagesDiv">
          <img src="../../assets/img/lianjiebao4.png" />
          <div class="advantagesTitle">全智能费用报销</div>
          <div class="advantagesContent">自动定位发票费用类型、自动提取事由，摒弃纸质粘票、 报销全流程线上化</div>
        </div>
        <div class="advantagesDiv">
          <img src="../../assets/img/lianjiebao5.png" />
          <div class="advantagesTitle">高适配流程引擎</div>
          <div class="advantagesContent">自定义审批流，多场景适配、 流程清晰易懂、 操作便捷</div>
        </div>
        <div class="advantagesDiv" style="display: flex;justify-content: center;align-items: center;font-size: 18px;color: #999999;">
          <div style="width:100px;text-align: center">
            <img src="../../assets/img/lianyunpiao6.png" />
            <div style="margin-top:10px">开发中</div>
          </div>
        </div>
      </div>
      <div class="titleImg">
        <img src="../../assets/img/ldyTitle2.png" />
      </div>
      <div class="structure">
        <img src="../../assets/img/structureImg4.png" />
      </div>
      <div class="titleImg">
        <img src="../../assets/img/ldyTitle3.png" />
      </div>
      <div class="case">
        <img src="../../assets/img/caseImg2.png" />
      </div>
    </div>
    <contactNav></contactNav>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import contactNav from "@/components/contactNav.vue";
import {mapState, mapActions} from "vuex";

export default {
  name: 'contact',
  components: {
    headerNav,
    footerNav,
    contactNav
  },
  data() {
    return {
      form: {},
      rules: {}
    };
  },
  methods: {},
  computed: {},
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.productsBody {
  width: 100%;
  min-height: 800px;

  .productsImg {
    width: 100%;
    height: 624px;
    background: url("../../assets/img/productsBg2.png") no-repeat center;
    div{
      width: 1200px;
      margin: 0 auto;
      .formBtn{
        width: 176px;
        height: 50px;
        background: #4C6DFD;
        border-radius: 8px;
        position: relative;
        top: 450px;
        border: none;
      }
      .formBtn:hover{
        background: #7D93F5;
        border-radius: 8px;
      }
    }
  }
  .titleImg{
    width:1200px;
    margin:40px auto;
    margin-top: 60px;
    text-align: center;
  }
  .advantages{
    width:1200px;
    margin:0px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .advantagesDiv{
      width: 330px;
      min-width: 330px;
      max-width: 330px;
      height: 246px;
      background: #FFFFFF;
      box-shadow: 0px 0px 40px 0px rgba(220,235,241,0.75);
      border-radius: 16px;
      margin: 10px auto;
      padding-top: 31px;
      padding-left: 30px;
      padding-right: 20px;
      cursor:pointer;
      transition: all  .2s  ease-in;
      .advantagesTitle{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 28px;
        color: #050829;
        line-height: 40px;
        font-style: normal;
        margin-top: 17px;
      }
      .advantagesContent{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #050829;
        line-height: 30px;
        text-align: left;
        font-style: normal;
        margin-top: 17px;
      }
    }
    .advantagesDiv:hover{
      transform: scale(1.04);
    }
  }
  .structure{
    width:100%;
    height:1260px;
    background: #F0F9FF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .case{
    width:1200px;
    margin:0px auto;
    margin-bottom: 100px;
  }

}
</style>
